import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row } from 'antd';
import MainLogo from 'app/assets/images/Midfunder-tracing.svg';
import { HeaderMenu } from 'entities/components/Header/HeaderMenu';
// import { HeaderButtons } from 'entities/components/Header/HeaderButtons';

interface IComponentProps {
  hideMenuItems?: boolean;
}

type TAllProps = IComponentProps & RouteComponentProps;

const HeaderComponent = (props: TAllProps) => {
  const goToMain = () => {
    const { history } = props;
    history.push('/');
  };

  const { hideMenuItems } = props;

  return (
    <div className="header">
      <Row>
        <div className="header-logo mr-4 pointer" onClick={goToMain}>
          <img src={MainLogo} alt="Main Logo" />
        </div>
        {!hideMenuItems && <HeaderMenu {...props} />}
        {/* {!hideMenuItems && <HeaderButtons />}*/}
      </Row>
    </div>
  );
};

export const Header = withRouter(HeaderComponent);
