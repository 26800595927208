import React from 'react';
import i18n from 'i18next';
import { Col } from 'antd';

interface IComponentProps {
  icon: string;
  title: string;
  text: string;
}

export const ValueItem = (props: IComponentProps) => {
  const { icon, text, title } = props;

  return (
    <Col xs={24} md={8} className="value-item">
      <div className="value-item__image">
        <img src={icon} alt={icon} />
      </div>
      <div className="value-item__title">{i18n.t(`ourValues.${title}`)}</div>
      <div className="value-item__text">{i18n.t(`ourValues.${text}`)}</div>
    </Col>
  );
};
