import React, { Component } from 'react';
import window from 'global/window';
import { AcademyTilePageLayout } from 'entities/components/Academy/AcademyTilePageLayout';
import { AcademyTilePageParagraph } from 'entities/components/Academy/AcademyTilePageParagraph';
import { EAcademyRoutes, tilesData } from 'entities/components/Academy/Academy.const';

class AcademyTilePageComponent extends Component {
  render() {
    const tileAddress = window.location && window.location.pathname.split('academy/')[1];
    const tileData = this.getTileData(tileAddress);

    return (
      <AcademyTilePageLayout date={tileData.date} title={tileData.title} imageName={tileData.imageName}>
        {tileData.paragraphs.map((item, index) => (
          <AcademyTilePageParagraph key={index} paragraph={item} />
        ))}
      </AcademyTilePageLayout>
    );
  }

  getTileData = (path: string) => {
    switch (path) {
      case EAcademyRoutes.KYC:
        return tilesData.kyc;
      case EAcademyRoutes.SourceOfFunds:
        return tilesData.sourceOfFunds;
      case EAcademyRoutes.MoneyLaundering:
      default:
        return tilesData.moneyLaundering;
    }
  };
}

export const AcademyTilePage = AcademyTilePageComponent;
