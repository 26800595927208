import React from 'react';
import i18n from 'i18next';
import { Row } from 'antd';
import { EAnchors } from 'common/const';
import { HowItWorksItem } from 'entities/components/HowItWorks/HowItWorksItem';

export const HowItWorks = () => {
  return (
    <div className="how-it-works-section" id={EAnchors.HowItWorks}>
      <div className="how-it-works-title">{i18n.t('howItWorks.title')}</div>
      <div className="how-it-works-tooltip">{i18n.t('howItWorks.text')}</div>
      <Row type="flex" gutter={64}>
        {howWeWorkItems.map(item => (
          <HowItWorksItem {...item} key={item.step} />
        ))}
      </Row>
    </div>
  );
};

const howWeWorkItems = [
  {
    step: '1',
    title: 'applicationTitle',
    text: 'applicationText'
  },
  {
    step: '2',
    title: 'verificationTitle',
    text: 'verificationText'
  },
  {
    step: '3',
    title: 'selectionTitle',
    text: 'selectionText'
  },
  {
    step: '4',
    title: 'paymentsTitle',
    text: 'paymentsText'
  }
];
