import window from 'global/window';

export function getAppDomainName(path?: string) {
  if (!window.location) {
    return '';
  }

  const url = new URL(window.location && window.location.href);
  const newUrl = `${url.protocol}//app.${url.host}`;

  return path ? newUrl + path : newUrl;
}

export function goTo(path: string) {
  window.location && (window.location.href = path);
}
