import React from 'react';
import { CTAComingSoon } from 'common/components/CTAComingSoon';

export const ModalSection = () => {
  return (
    <div>
      <p className="main-title">Ready to join?</p>
      <p className="main-text">Get your personal invite to our Beta.</p>
      <CTAComingSoon skipHeader={true} />
    </div>
  );
};
