import React from 'react';
import { Modal } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { ModalSection } from 'common/components/ModalSection';
import { MainSection } from 'entities/components/MainSection/MainSection';
import { Profits } from 'entities/components/Profits/Profits';
import { LoanTypes } from 'entities/components/LoanTypes/LoanTypes';
import { HowItWorks } from 'entities/components/HowItWorks/HowItWorks';
import { CTASection } from 'entities/components/CTASection/CTASection';
import { P2PLending } from 'entities/components/P2PLending/P2PLending';
import { Requirements } from 'entities/components/Requirements/Requirements';
import { WhyMidfunder } from 'entities/components/WhyMidfunder/WhyMidfunder';
import { Partnership } from 'entities/components/Partnership/Partnership';
import { AboutUs } from 'entities/components/AboutUs/AboutUs';
import { OurTeam } from 'entities/components/OurTeam/OurTeam';
import { CareerAtMidfunder } from 'entities/components/CareerAtMidfunder/CareerAtMidfunder';
import { OurValues } from 'entities/components/OurValues/OurValues';
import { ContactUs } from 'entities/components/ContactUs/ContactUs';
import { MCASection } from 'entities/components/MCASection/MCASection';
import { HeaderMeta } from './HeaderMeta';

interface IMainLayoutState {
  isModalVisible: boolean;
}

export class MainLayout extends React.Component<RouteComponentProps, IMainLayoutState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  render() {
    const metaProps = {
      route: 'index.html',
      titleTag: 'mainSection.htmlTitle',
      descriptionTag: 'mainSection.description',
    };
    const { isModalVisible } = this.state;
    return (
      <>
        <HeaderMeta {...{ ...this.props, ...metaProps }} />
        <div className="main-layout">
          <MainSection />
          <Profits />
          <MCASection />
          <LoanTypes />
          <HowItWorks />
          <CTASection />
          <P2PLending showModal={this.showModal} />
          <Requirements showModal={this.showModal} />
          <WhyMidfunder showModal={this.showModal} />
          <Partnership />
          <AboutUs />
          <OurTeam />
          <CareerAtMidfunder />
          <OurValues />
          <ContactUs />
          <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={() => this.setState({ isModalVisible: false })}
            width={800}
          >
            <ModalSection />
          </Modal>
        </div>
      </>
    );
  }
}

export default MainLayout;
