import React from 'react';
import i18n from 'i18next';
import { Route, Switch } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ERoutes } from 'common/const';
import { MainLayout } from 'common/components/MainLayout';
import { ScrollHandler } from 'common/components/ScrollHandler';
import { Header } from 'entities/components/Header/Header';
import { LegalNotice } from 'entities/components/PrivacyPolicy/LegalNotice';
import { UserTerms } from 'entities/components/PrivacyPolicy/UserTerms';
import { DataPrivacy } from 'entities/components/PrivacyPolicy/DataPrivacy';
import { LoanCalculator } from 'entities/components/LoanCalculator/LoanCalculator';
import { FAQ } from 'entities/components/FAQ/FAQPage';
import { Academy } from 'entities/components/Academy/Academy';
import { AcademyTilePage } from 'entities/components/Academy/AcademyTilePage';
import { Footer } from 'entities/components/Footer/Footer';
import { Careers } from 'entities/components/Careers/Careers';
import { CareerItem } from 'entities/components/Careers/CareerItem';

class App extends React.Component<WithTranslation> {
  componentDidMount() {
    document.documentElement.setAttribute('lang', i18n.language.slice(0, 2));
  }

  render() {
    return (
      <ScrollHandler>
        <Header />
        <Switch>
          <Route path={`/:lang([a-z]{2})?/${ERoutes.LegalNotice}`} component={LegalNotice} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.UserTerms}`} component={UserTerms} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.DataPrivacy}`} component={DataPrivacy} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.LoanCalculator}`} component={LoanCalculator} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.FAQInvestor}`} component={FAQ} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.FAQBorrower}`} component={FAQ} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.Academy}`} component={Academy} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.AcademyItem}`} component={AcademyTilePage} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.Careers}`} component={Careers} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.CareerItem}`} component={CareerItem} />
          <Route path={`/:lang([a-z]{2})?/${ERoutes.Main}`} component={MainLayout} />
        </Switch>
        <Footer />
      </ScrollHandler>
    );
  }
}

export default withTranslation()(App);
