import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'antd';

interface IComponentProps {
  title: JSX.Element;
  cardClassName?: string;
}

export const RequirementsItem = (props: PropsWithChildren<IComponentProps>) => {
  const { title, children, cardClassName } = props;

  return (
    <div className="requirements-item">
      <div className="requirements-item_title">{title}</div>
      <Row type="flex" justify="center" align="middle" className={`requirements-item_card ${cardClassName}`}>
        <Col span={24} className="text-center">
          {children}
        </Col>
      </Row>
    </div>
  );
};
