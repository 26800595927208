import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import { initGA } from 'common/analytics';
import App from 'app/pages/App';
import 'app/assets/styles/Styles.scss';

// i18n initialization
import 'i18n';

// React-GA initialization
// initGA('G-DZZQ0TFG1C');

// Render Setup

const rootElement = document.getElementById('root');
const app = (
  <Router>
    <App />
  </Router>
);

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}
