import React from 'react';
import { mdfEmail } from 'common/const';
import { CommonPrivacyLayout, MainTitle } from 'app/assets/styles/base/commonStyledComponents';
import { UserTermsTitle, UserTermsText, BoldText, UserTermsLink } from 'entities/components/PrivacyPolicy/UserTermsLayout';

export const UserTerms: React.FC = () => {
  return (
    <CommonPrivacyLayout>
      <MainTitle>TERMS OF USE</MainTitle>

      <UserTermsTitle>
        TERMS OF USE MIDFUNDER WEBSITE <br />
        (VERSION 1.0 FROM SEPTEMBER 2019)
      </UserTermsTitle>
      <UserTermsText>
        Please read the terms of use carefully. By accessing the website of Midfunder (hereinafter “Midfunder website”) and its
        related pages, you agree that you understand and agree to the following terms of use and legal information.
      </UserTermsText>

      <UserTermsTitle>INTRODUCTION</UserTermsTitle>
      <UserTermsText>
        Users (hereinafter also “borrowers”) of the Midfunder website are able to place a loan application on the site. The
        Midfunder business model consists of providing loans to users/borrowers with loan amounts financed by the direct sale and
        assignment of loan claims to other users (hereinafter referred to as “investors”) who provide the money. Users/investors
        are able to purchase loan claims from other users/borrowers on the Midfunder website.
      </UserTermsText>

      <UserTermsTitle>TARGET MARKET</UserTermsTitle>
      <UserTermsText>
        The Midfunder website is exclusively intended for natural or legal persons residing or domiciled in Switzerland and
        Liechtenstein.
      </UserTermsText>

      <UserTermsTitle>REGISTRATION AND USE</UserTermsTitle>
      <UserTermsText>
        Registration is required for the use of Midfnder’s services. Registration requires opening a personal user account
        (hereinafter “account”) and agreeing to these terms of use.
      </UserTermsText>
      <UserTermsText>
        <BoldText>
          In addition to legal persons, registration is only open to natural persons of legal age who are completely capable of
          acting. Minors (defined as people under the age of 18) and natural or legal persons residing or domiciled outside of
          Switzerland and Liechtenstein are excluded from using the Midfunder website and the products and services offered on it.
          A user may only operate one account; the account is personal and non-transferable.
        </BoldText>
      </UserTermsText>
      <UserTermsText>
        The user agrees to completely and truthfully provide all of the information necessary for registration. Any changes must
        be made immediately to ensure that the information is always complete and correct. Incomplete registrations will be
        deleted by Midfunder after a reasonable period of time.
      </UserTermsText>
      <UserTermsText>
        If incorrect or fraudulent information is given intentionally, Midfunder reserves the right to terminate the user
        agreement and any additional contractual relationships with the user in question without notice.
      </UserTermsText>
      <UserTermsText>
        Registration alone does not automatically entitle users to activate their credit search on the Midfunder website.
        Midfunder reserves the right to reject and not activate submitted applications without giving a reason.
      </UserTermsText>
      <UserTermsTitle>TARGET MARKET</UserTermsTitle>
      <UserTermsText>
        The Midfunder website is exclusively intended for natural or legal persons residing or domiciled in Switzerland and
        Liechtenstein.
      </UserTermsText>

      <UserTermsTitle>COSTS AND FEES</UserTermsTitle>
      <UserTermsText>
        Registration on the Midfunder website is free of charge. Costs originating from the users are charged to the users by
        Midfunder in accordance with the fee regulations. In addition, the user is billed separately for all third-party fees
        (e.g. postal costs, costs for the provision of collateral etc.).
      </UserTermsText>

      <UserTermsTitle>DATA SECURITY AND THE USE OF DATA</UserTermsTitle>
      <UserTermsText>
        Users provide Midfunder with information for the service provided such as the following: first and last names, address and
        place of residence, user names, email address, description of loan application and for borrowers information on financial
        status, etc. This information is saved on servers and is used by Midfunder exclusively to perform the service. The
        information is stored in a database for this purpose. Midfunder takes all precautions to protect the privacy of users and
        their data.
      </UserTermsText>
      <UserTermsText>
        Users are requested to provide their personal data at several points on the Midfunder website. Due to the nature of the
        Internet, data circulates across borders via servers abroad. If users decide to receive their personal Midfunder data via
        the Internet or email, it will be handled in accordance with applicable data protection regulations and protected against
        unauthorised access by third parties. The data are processed by and for Midfunder and its affiliates. Third parties both
        in Switzerland and abroad can be contracted with the processing of the data. Midfunder and its affiliates use the data
        exclusively for implementing the contract and for the purposes explicitly mentioned in the terms of use and Midfunder’s
        General Terms and Conditions.
      </UserTermsText>
      <UserTermsText>
        Please note that confidentiality cannot be completely ensured when transmitting emails. Under certain circumstances,
        emails can be read, forged, falsified or deleted by third parties. Emails can also contain harmful computer programs such
        as viruses, worms or Trojan horses.
      </UserTermsText>
      <UserTermsText>
        <BoldText>
          By using the Midfunder website, users agree to the processing of their data in the manner and for the purposes laid out
          above.
        </BoldText>
      </UserTermsText>

      <UserTermsTitle>BORROWING CAPACITY AND CREDIT ASSESSMENT (BORROWER)</UserTermsTitle>
      <UserTermsText>
        Loan applications made by users on the Midfunder website are carefully examined before being approved and published on the
        Midfunder website. In particular, Midfunder verifies users’ creditworthiness relying on the information and documents
        provided by users for this purpose. Once creditworthiness has been positively verified, users receive a credit agreement.
        If the information given by the user/borrower proves to be false or there is negative information regarding
        creditworthiness, Midfunder can withdraw at any time from the credit agreement and any additional contractual
        relationships with the user/borrower. Any losses resulting from cancellation of the contract are born by the
        user/borrower.
      </UserTermsText>
      <UserTermsText>
        The user/borrower authorizes Midfunder to obtain all information necessary for processing the loan application and the
        loan agreement from public and private sources as well as from the Consumer Credit Information Office (IKO) and the
        Central Office for Credit Information (ZEK) and to register the loan and the contractual relationship with the IKO and the
        ZEK. The user/borrower notes that the ZEK and the IKO inform their associated lenders of their responsibilities from the
        loan agreement for a new loan application upon request.
      </UserTermsText>

      <UserTermsTitle>USER FINANCING COMMITMENTS TO LOAN APPLICATIONS (INVESTORS)</UserTermsTitle>
      <UserTermsText>
        Once the loan application has been approved by Midfunder it will be accessible on the Midfunder website free of charge.
        The user/investor is able to contribute financially to an activated loan project within a certain period (hereinafter
        “investment period”) until the entire loan amount has been funded. To participate, investors enter the financing amount
        (at least CHF 500 or a multiple thereof) in the respective area on the Midfunder website. The user/investor confirms the
        financing commitment by clicking on a button.
      </UserTermsText>
      <UserTermsText>
        The financing commitment is binding and the user/investor in question is obliged to make the corresponding amount
        available at the conclusion of the loan agreement with the user submitting the loan application by purchasing the loan
        claim in the amount of the financing commitment.
      </UserTermsText>

      <UserTermsTitle>AMENDMENT OF OR WITHDRAWAL FROM THE FINANCING COMMITMENT IS NOT POSSIBLE.</UserTermsTitle>
      <UserTermsText>
        The investor will subsequently receive a PDF file via eMessage with the offer signed by Midfunder to enter into an
        “agreement on sale, transfer and management against payment of future (partial) receivables under a loan agreement”
        (hereinafter <BoldText>“agreement“</BoldText>). After receiving the eMessage, investors accept the offer to enter into the
        agreement by confirming their intention to enter into the agreement. By sending the confirmation, the agreement –
        including the purchase of receivables between the investor and Midfunder contained within – is binding.
      </UserTermsText>

      <UserTermsTitle>
        THE AGREEMENT/PURCHASE OF RECEIVABLES BETWEEN THE INVESTOR AND MIDFUNDER IS SUBJECT TO THE FOLLOWING SUSPENSIVE
        CONDITIONS:
      </UserTermsTitle>
      <UserTermsText>
        A sufficiently financed loan project materialises, meaning that the loan amount requested by the user/borrower is covered
        by the sum of all the financing commitments from investors within the investment period or that the user/ borrower agrees
        to a lower loan amount; and that an effective loan agreement is concluded between the user/borrower and Midfunder.
      </UserTermsText>
      <UserTermsText>
        Following the end of the investment period, the investor is informed as to whether a loan project has successfully
        materialised.
      </UserTermsText>

      <UserTermsTitle>
        SALES BETWEEN REGISTERED INVESTORS TO RECEIVE THEIR CAPITAL BEFORE THE END OF THE TERM OF THE LOAN
      </UserTermsTitle>
      <UserTermsText>
        Investors who have entered sell order will continue to receive all amortisation and interest payments of the borrower
        until the time a buyer purchases. Payments received from this point in time will be paid after successful completion of
        the sale to the buyer. The purchase price is adjusted accordingly so that the purchase price charged in each case
        correctly reflects the cash flows. In case the sale falls through, all cash flows go to the seller.
      </UserTermsText>
      <UserTermsText>
        It can not be sold if an instalment payment of the borrower had to be reminded at the time of the sale.
      </UserTermsText>
      <UserTermsText>
        Lenders who would like to sell one or several investments can enter their request in their online cockpit. As soon as a
        buyer is found and the purchase price is paid, it is transferred and the seller receives the money.
      </UserTermsText>
      <UserTermsText>
        The Midfunder secondary market is offered free of charge to all lenders. Midfunder does not charge a commission for the
        transacted credit. As with all investments, lenders pay the full service charge on all repayments, including bought or
        sold on the secondary market.
      </UserTermsText>
      <UserTermsText>
        All consequences in connection with a failure and the associated effect of the solidarity agreement shall be borne by the
        seller until payment of the purchase price and the associated settlement of the investment transfer.
      </UserTermsText>

      <UserTermsTitle>NO GUARANTEE, CONSULTATION OR RECOMMENDATION (INVESTOR)</UserTermsTitle>
      <UserTermsText>
        Midfunder carefully evaluates the creditworthiness of users who place loan applications on the Midfunder website. For
        this, Midfunder relies on information and documentation provided by the user for evaluation purposes. The result of the
        evaluation constitutes neither a guarantee of the borrower’s ability to pay nor a recommendation for the purchase of the
        loan claim in question. Midfunder accordingly accepts no liability for any losses by investors resulting from loan
        defaults. In particular, Midfunder provides no consultation services. Investors are cautioned that the purchase of loan
        claims can be associated with risks that can include the loss of part or the entire claim amount.
      </UserTermsText>

      <UserTermsTitle>COOKIES</UserTermsTitle>
      <UserTermsText>
        Users acknowledge and agree to the use of “cookies” in certain instances to provide them with customized information. The
        term “cookie” refers to an information file stored on the hard disk that allows a computer to be recognized during
        subsequent visits to the Midfunder website. Users acknowledge that their browsers can be set to inform them when they
        receive a “cookie.”
      </UserTermsText>

      <UserTermsTitle>GOOGLE CONVERSION TRACKING</UserTermsTitle>
      <UserTermsText>
        The user acknowledges and agrees to Midfunder’s use of Google’s conversion tracking technology. When users access the
        Midfunder website, a temporary cookie is placed on their computer. This cookie loses its validity after 30 days and is not
        used for personal identification. If a user has visited a particular page on the Midfunder website and the cookie has not
        yet expired, Google and Midfunder see that the user has clicked on the advertisement and has been redirected to this page.
        Users acknowledge that they can refuse these cookies by setting their browser so that th{' '}
      </UserTermsText>

      <UserTermsTitle>NO OFFER</UserTermsTitle>
      <UserTermsText>
        The information published on the Midfunder website does not constitute a solicitation, an offer or a recommendation to
        enter into a loan agreement, purchase a claim, carry out a transaction or conclude or enter into any other legal
        transaction.
      </UserTermsText>

      <UserTermsTitle>GUARANTEE AND LIABILITY</UserTermsTitle>
      <UserTermsText>
        Midfunder presents the information on the Midfunder website without any guarantees. Midfunder accepts no responsibility
        and makes no guarantee that the Midfunder website is functional, error-free or virus-free.
      </UserTermsText>
      <UserTermsText>
        Midfunder expressly excludes any liability for direct or indirect damages resulting from access to the elements of the
        Midfunder website and their use, as well as resulting from the inability to access or use the website and its elements.
        Midfunder also rejects any liability for manipulations to the IT system of the Internet user (virus, worms, Trojan horses
        etc.) by third parties.
      </UserTermsText>
      <UserTermsText>
        Users may not make their accounts available to third parties and shall be fully liability for any activities that occur
        under their accounts. The password and user name must be kept secret and may not be shared with third parties. Midfunder
        excludes liability for any abuses.
      </UserTermsText>

      <UserTermsTitle>USE OF CONTACT INFORMATION</UserTermsTitle>
      <UserTermsText>
        Midfunder and its affiliates are authorized to use the data provided to Midfunder for marketing purposes. Users agree in
        particular to Midfunder and its affiliates occasionally sending them information on the company, its products and its
        services via mail, email and text messaging. Users acknowledge that they can refuse the use of their customer data for
        marketing purposes at any time in writing to Midfunder AG, Dufourstrasse 43, 8008 Zürich or by email to
        <UserTermsLink> {mdfEmail}</UserTermsLink>
      </UserTermsText>

      <UserTermsTitle>LINKS</UserTermsTitle>
      <UserTermsText>
        The Midfunder website contains hyperlinks to other websites. Midfunder has not verified these hyperlinks and is not
        responsible for the content of the linked websites. Users use links to other websites at their own risk.
      </UserTermsText>

      <UserTermsTitle>COPYRIGHT</UserTermsTitle>
      <UserTermsText>
        All elements of the Midfunder website are copyrighted and trademarked. Any commercial use of these elements is only
        permitted with prior written consent from Midfunder. “Midfunder” is a registered trademark.
      </UserTermsText>

      <UserTermsTitle>CHANGES TO THE TERMS OF USE</UserTermsTitle>
      <UserTermsText>
        Midfunder reserves the right to modify these terms of use at any time. Such changes automatically take effect when
        published on the Midfunder website.
      </UserTermsText>

      <UserTermsTitle>JURISDICTION AND APPLICABLE LAW</UserTermsTitle>
      <UserTermsText>
        The exclusive place of jurisdiction for any disputes between users of the Midfunder website and Midfunder arising from
        visiting or using the Midfunder website is Midfunder’s headquarters. Midfunder is also free to bring suit at the user’s
        place of residence. Swiss material law applies.
      </UserTermsText>
    </CommonPrivacyLayout>
  );
};
