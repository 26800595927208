import { IParallaxData } from 'common/components/ParalaxContainer';

export const tilesPlx: IParallaxData[] = [
  {
    start: 'self',
    startOffset: '10vh',
    duration: '20vh',
    properties: [
      {
        startValue: 30,
        endValue: -30,
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  }
];

export const mainImagePlxBackground: IParallaxData[] = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: -60,
        endValue: 100,
        property: 'translateY'
      }
    ]
  }
];
export const mainImagePlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '80vh',
    properties: [
      {
        startValue: 60,
        endValue: -60,
        property: 'translateY'
      },
      {
        startValue: 20,
        endValue: -20,
        property: 'translateX'
      }
    ]
  }
];

export const rocketPlxBackground: IParallaxData[] = [
  {
    start: 'self',
    startOffset: '-10vh',
    duration: '100vh',
    properties: [
      {
        startValue: -40,
        endValue: 40,
        property: 'translateY'
      },
      {
        startValue: 40,
        endValue: -40,
        property: 'translateX'
      },
      {
        startValue: 1,
        endValue: 0.2,
        property: 'opacity'
      }
    ]
  }
];
export const rocketPlx: IParallaxData[] = [
  {
    start: 'self',
    startOffset: '-10vh',
    duration: '100vh',
    properties: [
      {
        startValue: 40,
        endValue: -40,
        property: 'translateY'
      },
      {
        startValue: -40,
        endValue: 40,
        property: 'translateX'
      }
    ]
  }
];
export const CTAManBackgroundPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: 10,
        endValue: -40,
        property: 'translateY'
      }
    ]
  }
];
export const CTAManPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: 0,
        endValue: -200,
        property: 'translateY'
      }
    ]
  }
];
export const planeBackgroundPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '150vh',
    properties: [
      {
        startValue: 10,
        endValue: 150,
        property: 'translateX'
      }
    ]
  }
];
export const planePlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '150vh',
    properties: [
      {
        startValue: 20,
        endValue: 100,
        property: 'translateY'
      },
      {
        startValue: 400,
        endValue: -100,
        property: 'translateX'
      }
    ]
  }
];
export const moneyTreePlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: 30,
        endValue: -30,
        property: 'translateY'
      }
    ]
  }
];
export const moneyTreePlanets: IParallaxData[] = [
  {
    start: 'self',
    duration: '100vh',
    properties: [
      {
        startValue: -30,
        endValue: 30,
        property: 'translateY'
      }
    ]
  }
];
export const partnerTeamCloudPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '100vh',
    startOffset: '-30vh',
    properties: [
      {
        startValue: -200,
        endValue: -100,
        property: 'translateY'
      }
    ]
  }
];
export const manOnStairsPlanetsPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '150vh',
    startOffset: '-30vh',
    properties: [
      {
        startValue: -150,
        endValue: 50,
        property: 'translateY'
      }
    ]
  }
];
export const aboutUsPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '150vh',
    startOffset: '-30vh',
    properties: [
      {
        startValue: 50,
        endValue: -30,
        property: 'translateY'
      }
    ]
  }
];
export const aboutUsPlanetsPlx: IParallaxData[] = [
  {
    start: 'self',
    duration: '150vh',
    startOffset: '-30vh',
    properties: [
      {
        startValue: -150,
        endValue: -50,
        property: 'translateY'
      }
    ]
  }
];
