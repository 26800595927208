import React from 'react';
import { Col, Row } from 'antd';
import { mdfEmail, mdfTel } from 'common/const';
import FBIcon from 'app/assets/icons/FBIcon.svg';
import InstaIcon from 'app/assets/icons/InstaIcon.svg';
import LinkedInIcon from 'app/assets/icons/LinkedInIcon.svg';
import TwitterIcon from 'app/assets/icons/TwitterIcon.svg';
import SwissMade from 'app/assets/images/SwissMade.svg';
import SEC from 'app/assets/images/SEC.png';
// import EDOB from 'app/assets/images/EDOB.png';

export const ContactsSection = () => {
  return (
    <Row type="flex" gutter={[32, 32]} className="contact-section">
      <Col xl={4} md={8} sm={12} xs={24} className="fs-xs">
        MidFunder AG <br />
        Dufourstrasse 43
        <br />
        8008 Zürich
      </Col>
      <Col xl={4} md={8} sm={12} xs={24}>
        <div>
          <a href={`tel:${mdfTel}`}>{mdfTel}</a>
        </div>
        <div>
          <a href={`mailto:${mdfEmail}`}>{mdfEmail}</a>
        </div>
      </Col>
      <Col xl={4} md={8} sm={12} xs={12}>
        <Row type="flex" justify="space-around" align="top">
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={FBIcon} alt="Facebook icon" />
          </a>
          <a href="https://www.instagram.com/midfunder/" target="_blank" rel="noopener noreferrer">
            <img src={InstaIcon} alt="Instagram icon" />
          </a>
          <a href="https://www.linkedin.com/company/midfunder/" target="_blank" rel="noopener noreferrer">
            <img src={LinkedInIcon} alt="Facebook icon" />
          </a>
          <a href="https://twitter.com/midfunder" target="_blank" rel="noopener noreferrer">
            <img src={TwitterIcon} alt="Twitter icon" />
          </a>
        </Row>
      </Col>
      <Col xl={4} md={8} sm={12} xs={12}>
        <img src={SwissMade} alt="Swissmade icon" width="100%" />
      </Col>
      <Col xl={4} md={8} sm={12} xs={12}>
        <img src={SEC} alt="SEC icon" width="100%" />
      </Col>
      <Col xl={4} md={8} sm={12} xs={12}>
        {/* <span style={{ color: 'black' }}>Federal Data Protection and Information Commissioner</span>*/}
        {/* <img src={EDOB} alt="EDOB icon" width="100%" />*/}
      </Col>
    </Row>
  );
};
