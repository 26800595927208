import React from 'react';
import i18n from 'i18n';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

interface IComponentProps {
  route: string;
  titleTag: string;
  descriptionTag: string;
}

export const HeaderMeta = (props: RouteComponentProps & IComponentProps) => {
  const languages = ['en', 'de'];
  const { location, route, titleTag, descriptionTag } = props;
  let pathLang = '';
  const components = location.pathname.split('/');
  if (components.length >= 2 && languages.includes(components[1])) {
    pathLang = components[1];
  }
  let pathRoute = '';
  const lastComponent = components[components.length - 1];
  if (lastComponent !== '' && !languages.includes(lastComponent)) {
    pathRoute = lastComponent;
  }
  let pathCanonical = pathLang === 'en' ? '' : pathLang;
  if (pathCanonical !== '') {
    pathCanonical += '/';
  }
  if (pathRoute !== '' && pathRoute !== 'index.html') {
    pathCanonical += pathRoute;
  }

  return (
    <Helmet>
      <link rel="canonical" href={`https://midfunder.com/${pathCanonical}`} />
      {languages.map((lang) => {
        if (lang === pathLang) {
          return undefined;
        }
        return <link key={lang} rel="alternate" hrefLang={lang} href={`https://midfunder.com/${lang}/${route}`} />;
      })}
      <title>{i18n.t(titleTag)}</title>
      <meta name="description" content={i18n.t(descriptionTag)} />
    </Helmet>
  );
};

export default HeaderMeta;
