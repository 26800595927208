import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Row, Col } from 'antd';
import { EAnchors, mdfEmail } from 'common/const';
import { goTo } from 'common/helpers/domainName.helper';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import { partnerTeamCloudPlx } from 'common/PlxConfig';
import PartnerTeam from 'app/assets/images/PartnerTeam.svg';
import PartnerTeamCloud from 'app/assets/images/PartnerTeamCloud.svg';
import { PartnerWithUsPoints } from 'entities/components/Partnership/PartnerWithUsPoints';

export const PartnerWithUs = () => {
  const mailToMDF = () => {
    goTo(`mailto:${mdfEmail}`);
  };

  return (
    <div className="partner-with-us-section" id={EAnchors.Partner}>
      <Row type="flex" gutter={32} align="middle">
        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
          <div className="partner-with-us_title">
            <Trans i18nKey="partnership.withUsTitle">
              <span className="ff-bold">Partner</span>
              <br />
              with MidFunder
            </Trans>
          </div>
          <div className="partner-with-us_text">{i18n.t('partnership.withUsText')}</div>
          <PartnerWithUsPoints />
          <button className="partner-with-us_btn" onClick={mailToMDF}>
            {i18n.t('partnership.withUsButton')}
          </button>
        </Col>
        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="partner-with-us_image">
          <ParalaxContainer childrenParallaxData={[{ IParallaxData: partnerTeamCloudPlx }]} className="partnerTeam">
            <img src={PartnerTeamCloud} alt="Partner Team" width="100%" />
            <img src={PartnerTeam} alt="Partner Team" width="100%" />
          </ParalaxContainer>
        </Col>
      </Row>
    </div>
  );
};
