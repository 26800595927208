import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';

interface IComponentProps {
  icon: string;
  memberName: string;
  memberPos: string;
  memberAchieve: string;
}

export const MemberTileHorizontal = (props: IComponentProps) => {
  const { icon, memberAchieve, memberName, memberPos } = props;
  return (
    <Col xs={24} md={12} className="member-tile">
      <Row type="flex" align="stretch">
        <Col xs={24} lg={10} xl={9} className="member-tile__image">
          <img src={icon} alt={icon} width="100%" />
        </Col>
        <Col xs={24} lg={14} xl={15}>
          <div className="member-tile__name">{memberName}</div>
          <div className="member-tile__position">{i18n.t(`ourTeam.${memberPos}`)}</div>
          <div className="member-tile__achievement">{i18n.t(`ourTeam.${memberAchieve}`)}</div>
        </Col>
      </Row>
    </Col>
  );
};
