import { Trans } from 'react-i18next';
import React from 'react';

export enum EAcademyRoutes {
  MoneyLaundering = 'money-laundering',
  KYC = 'kyc',
  SourceOfFunds = 'source-of-funds'
}

export const academyTiles = [
  { path: '/academy/money-laundering', imageName: 'MoneyLaundry', title: 'academyTileTitle1', text: 'academyTileText1' },
  { path: '/academy/kyc', imageName: 'KYCVerif', title: 'academyTileTitle2', text: 'academyTileText2' },
  { path: '/academy/source-of-funds', imageName: 'HeapOfCash', title: 'academyTileTitle3', text: 'academyTileText3' }
];

export const moneyLaunderingParagraphs = [
  { text: ['academyFirstTileText0-1', 'academyFirstTileText0-2'] },
  {
    title: 'academyFirstTilePar1Title',
    text: ['academyFirstTilePar1Text1', 'academyFirstTilePar1Text2']
  },
  {
    title: 'academyFirstTilePar2Title',
    text: ['academyFirstTilePar2Text1', 'academyFirstTilePar2Text2', 'academyFirstTilePar2Text3', 'academyFirstTilePar2Text4']
  },
  {
    title: 'academyFirstTilePar3Title',
    text: ['academyFirstTilePar3Text1', 'academyFirstTilePar3Text2', 'academyFirstTilePar3Text3', 'academyFirstTilePar3Text4']
  },
  {
    title: 'academyFirstTilePar4Title',
    text: [
      'academyFirstTilePar4Text1',
      'academyFirstTilePar4Text2',
      'academyFirstTilePar4Text3',
      'academyFirstTilePar4Text4',
      'academyFirstTilePar4Text5'
    ]
  },
  {
    title: 'academyFirstTilePar5Title',
    text: ['academyFirstTilePar5Text1', 'academyFirstTilePar5Text2', 'academyFirstTilePar5Text3', 'academyFirstTilePar5Text4']
  },
  {
    title: 'academyFirstTilePar6Title',
    text: ['academyFirstTilePar6Text1', 'academyFirstTilePar6Text2']
  }
];

export const KYCParagraphs = [
  {
    text: ['academySecondTileText0-1', 'academySecondTileText0-2']
  },
  {
    title: 'academySecondTilePar1Title',
    text: ['academySecondTilePar1Text1', 'academySecondTilePar1Text2', 'academySecondTilePar1Text3']
  },
  {
    title: 'academySecondTilePar2Title',
    text: ['academySecondTilePar2Text1', 'academySecondTilePar2Text2', 'academySecondTilePar2Text3', 'academySecondTilePar2Text4']
  },
  {
    title: 'academySecondTilePar3Title',
    text: ['academySecondTilePar3Text1']
  },
  {
    title: 'academySecondTilePar4Title',
    text: ['academySecondTilePar4Text1']
  }
];

export const sourceOfFundsParagraphs = [
  {
    text: ['academyThirdTileText0-1', 'academyThirdTileText0-2', 'academyThirdTileText0-3', 'academyThirdTileText0-4']
  },
  {
    title: 'academyThirdTilePar1Title',
    text: [
      'academyThirdTilePar1Text1',
      'academyThirdTilePar1Text2',
      'academyThirdTilePar1Text3',
      'academyThirdTilePar1Text4',
      'academyThirdTilePar1Text5'
    ]
  },
  {
    title: 'academyThirdTilePar2Title',
    text: ['academyThirdTilePar2Text1', 'academyThirdTilePar2Text2']
  },
  {
    title: 'academyThirdTilePar3Title',
    text: ['academyThirdTilePar3Text1', 'academyThirdTilePar3Text2', 'academyThirdTilePar3Text3']
  },
  {
    title: 'academyThirdTilePar4Title',
    text: ['academyThirdTilePar4Text1', 'academyThirdTilePar4Text2']
  },
  {
    title: 'academyThirdTilePar5Title',
    text: ['academyThirdTilePar5Text1', 'academyThirdTilePar5Text2', 'academyThirdTilePar5Text3']
  },
  {
    title: 'academyThirdTilePar6Title',
    text: ['academyThirdTilePar6Text1']
  },
  {
    title: 'academyThirdTilePar7Title',
    text: ['academyThirdTilePar7Text1']
  }
];

const mockDate = '17 May 2020';

export const tilesData = {
  moneyLaundering: {
    date: mockDate,
    title: (
      <Trans i18nKey="academyItemTitle1">
        What is <span className="ff-bold">Money Laundering</span> and why do we take it seriously
      </Trans>
    ),
    imageName: 'MoneyLaundryFull',
    paragraphs: moneyLaunderingParagraphs
  },
  kyc: {
    date: mockDate,
    title: (
      <Trans i18nKey="academyItemTitle2">
        {' '}
        <span className="ff-bold">What is KYC</span> and why do we need it?
      </Trans>
    ),
    imageName: 'KYCVerifFull',
    paragraphs: KYCParagraphs
  },
  sourceOfFunds: {
    date: mockDate,
    title: (
      <Trans i18nKey="academyItemTitle3">
        What is a <span className="ff-bold">“Source of Funds”</span> check?
      </Trans>
    ),
    imageName: 'HeapOfCashFull',
    paragraphs: sourceOfFundsParagraphs
  }
};
