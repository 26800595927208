import React from 'react';
import i18n from 'i18next';
import { Row } from 'antd';

interface IComponentProps {
  icon: string;
  title: string;
  text: string;
  bg: string;
}

export const LoanTypesItem = (props: IComponentProps) => {
  const { icon, title, text, bg } = props;

  return (
    <Row type="flex" className="flex-nowrap">
      <div className="loan-types_item_image" style={{ background: bg }}>
        <img src={icon} alt={icon} />
      </div>
      <div className="loan-types_item_description">
        <div className="loan-types_item_description_title">{i18n.t(`loanTypes.${title}`)}</div>
        <div className="loan-types_item_description_text">{i18n.t(`loanTypes.${text}`)}</div>
      </div>
    </Row>
  );
};
