import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import i18n from 'i18next';
import { HashLink as Link } from 'react-router-hash-link';
import ArrowDown from 'app/assets/icons/ArrowDown.svg';
import { languageMenu, menuItems } from 'entities/components/Header/Header.const';

export class HeaderMenu extends React.Component<RouteComponentProps> {
  render() {
    const langTitle = this.defineLang();

    return (
      <Menu
        mode="horizontal"
        className="header-menu"
        getPopupContainer={(triggerNode: Element) => triggerNode as HTMLElement}
        overflowedIndicator={<Icon type="menu" style={{ fontSize: '18px' }} />}
      >
        {menuItems.map(item =>
          item.menuPoints.length ? (
            <Menu.SubMenu
              title={
                <>
                  {i18n.t(`headerMenu.${item.title}`)} <img src={ArrowDown} alt="Arrow down" />
                </>
              }
              key={item.title}
            >
              <Menu.ItemGroup>
                {item.menuPoints.map(it => (
                  <Menu.Item key={it.title}>
                    <Link to={`./${it.value}`}>{i18n.t(`headerMenu.${it.title}`)}</Link>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.title}>
              <Link to={`./${item.anchor}`}>{i18n.t(`headerMenu.${item.title}`)}</Link>
            </Menu.Item>
          )
        )}
        <Menu.SubMenu
          title={
            <>
              {langTitle} <img src={ArrowDown} alt="Arrow down" />
            </>
          }
        >
          {languageMenu.menuPoints.map(item => (
            <Menu.Item onClick={() => this.changeLang(item.value, item.title)} key={item.title}>
              {item.title}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    );
  }

  isLanguageValue = (lang: string) => languageMenu.menuPoints.find(item => item.value === lang);

  changeLang = (lang: string, langTitle: string) => {
    const { history, location } = this.props;
    let dst = '';
    const components = location.pathname.split('/');
    if (components.length >= 2 && this.isLanguageValue(components[1])) {
      components[1] = lang;
      dst = components.join('/');
    } else {
      dst = `/${lang}${location.pathname}`;
    }
    if (components[components.length - 1] === '') {
      dst += 'index.html';
    }
    dst += location.hash;
    i18n.changeLanguage(lang).then();
    history.push(dst);
  };

  defineLang = () => {
    const definiteLang = i18n.language.slice(0, 2);
    const isLangExist = this.isLanguageValue(definiteLang);
    /* Language will always be found because the i18n provides the default language (De) if definite language doesn't exist
    in whitelist from config. But we need this check because linter doesn't know about it.*/
    return isLangExist ? isLangExist.title : '';
  };
}
