export function queryToObject(params: string) {
  const urlParams = new URLSearchParams(params);
  const entries = urlParams.entries();

  const result: any = {};
  for (const entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}
