import React from 'react';
import { Col, Row } from 'antd';
import { Trans } from 'react-i18next';
import { PrivacyLinks } from 'entities/components/Footer/PrivacyLinks';
import { ContactsSection } from 'entities/components/Footer/ContactsSection';
import { PrivacyInfo } from 'entities/components/Footer/PrivacyInfo';

interface IComponentProps {
  mode?: 'full' | 'minimal';
}

export const Footer = (props: IComponentProps) => {
  const { mode = 'full' } = props;

  return (
    <div className="footer">
      {mode === 'full' && (
        <Row type="flex" justify="center" className="footer-warning">
          <Col xl={10} lg={14} md={18} xs={24}>
            <Trans i18nKey="footer.warningTooltip">
              Warning: Lending money to any business venture presents a risk of capital loss. <br />
              Borrow responsibly and not beyond your repayment capacity.
            </Trans>
          </Col>
        </Row>
      )}

      <div className="footer-info">
        <ContactsSection />
        {mode === 'full' && <PrivacyInfo />}
        <PrivacyLinks hidePrivacyLinks={mode !== 'full'} />
      </div>
    </div>
  );
};
