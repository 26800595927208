export const font = {
  normal: 'Inter Regular',
  medium: 'Inter Medium',
  bold: 'Inter Bold'
};

export const fontSize = {
  xxxs: '12px',
  xxs: '14px',
  xs: '16px',
  sm: '18px',
  md: '20px',
  lg: '24px',
  xlg: '28px',
  xxlg: '36px'
};

export const color = {
  white: '#FFFFFF',
  black: '#1F1F1F',
  blackLight: '#333333',
  grayDark: '#666666',
  gray: '#8B8B8B',
  grayLight: '#E4E4E4',
  blue: '#0285FF',
  bluePale: '#F8FBFD'
};

const spacer = 8;

export const space = {
  xxxs: `${spacer / 4}px`,
  xxs: `${spacer / 2}px`,
  xs: `${spacer}px`,
  sm: `${spacer * 1.5}px`,
  md: `${spacer * 2}px`,
  lg: `${spacer * 2.5}px`,
  xlg: `${spacer * 3}px`,
  xxlg: `${spacer * 3.5}px`,
  xxxlg: `${spacer * 4}px`
};

export const mediaBreakpoints = {
  sm: '767px',
  md: '1199px'
};
