/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ERoutes, mdfEmail, mdfTel } from 'common/const';
import { CommonPrivacyLayout, MainTitle } from 'app/assets/styles/base/commonStyledComponents';
import { LegalNoticeText, LegalNoticeLink } from 'entities/components/PrivacyPolicy/LegalNoticeLayout';
import { HeaderMeta } from 'common/components/HeaderMeta';

// eslint-disable-next-line arrow-body-style
export const LegalNotice: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const metaProps = {
    route: ERoutes.LegalNotice,
    titleTag: 'legalNotice.htmlTitle',
    descriptionTag: 'legalNotice.description',
  };

  return (
    <>
      <HeaderMeta {...{ ...props, ...metaProps }} />
      <CommonPrivacyLayout>
        <MainTitle>LEGAL NOTICE</MainTitle>
        <LegalNoticeText>Midfunder AG</LegalNoticeText>
        <LegalNoticeText>Dufourstrasse 43</LegalNoticeText>
        <LegalNoticeText>8008 Zürich</LegalNoticeText>
        <LegalNoticeText>Phone: {mdfTel}</LegalNoticeText>
        <LegalNoticeLink href={`mailto:${mdfEmail}`}>{mdfEmail}</LegalNoticeLink>
        <LegalNoticeText>Commercial register: CHE-142.018.503</LegalNoticeText>
        <LegalNoticeText>Company headquarters: 8008 Zürich</LegalNoticeText>
        <LegalNoticeText>
          Responsibility of our website: <LegalNoticeLink href={`mailto:${mdfEmail}`}>{mdfEmail}</LegalNoticeLink>
        </LegalNoticeText>
      </CommonPrivacyLayout>
    </>
  );
};

export default LegalNotice;
