import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { EAnchors } from 'common/const';
import Case from 'app/assets/icons/Case.svg';
import Calendar from 'app/assets/icons/Calendar.svg';
import Turn from 'app/assets/icons/Turn.svg';
import Handshake from 'app/assets/icons/Handshake.svg';
import Package from 'app/assets/icons/Package.svg';
import Pay from 'app/assets/icons/Pay.svg';
import { LoanTypesItem } from 'entities/components/LoanTypes/LoanTypesItem';

export const LoanTypes = () => {
  return (
    <div className="loan-types-section" id={EAnchors.LoanTypes}>
      <div className="loan-types_title">{i18n.t('loanTypes.title')}</div>
      <div className="loan-types_text">{i18n.t('loanTypes.text')}</div>
      <Row type="flex" gutter={[24, 48]}>
        {loanTypes.map((item, index) => (
          <Col xs={24} md={12} lg={8} key={index}>
            <LoanTypesItem {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

const loanTypes = [
  {
    icon: Pay,
    title: 'cashAdvanceTitle',
    text: 'cashAdvanceText',
    bg: '#fff7cE'
  },
  {
    icon: Calendar,
    title: 'shortTermLoanTitle',
    text: 'shortTermLoanText',
    bg: '#eef5ff'
  },
  {
    icon: Turn,
    title: 'accountsReceivableTitle',
    text: 'accountsReceivableText',
    bg: '#f6e2eb'
  },
  {
    icon: Handshake,
    title: 'businessAcquisitionTitle',
    text: 'businessAcquisitionText',
    bg: '#eef5ff'
  },
  {
    icon: Package,
    title: 'equipmentFinancingTitle',
    text: 'equipmentFinancingText',
    bg: '#e9e9f3'
  },
  {
    icon: Case,
    title: 'businessLoanTitle',
    text: 'businessLoanText',
    bg: '#fff7cE'
  }
];
