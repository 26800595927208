import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

interface IProps {
  skipHeader: boolean;
}

class CTAComingSoonComponent extends React.Component<IProps> {
  render() {
    return (
      <div className="cta-input">
        <Row type="flex" align="middle" justify="start">
          <Col>
            <Button size="large" type="primary" href="https://www.surveymonkey.com/r/6KYKMHH">
              {i18n.t('comingSoonBlock.button')}
            </Button>
          </Col>
          <Col style={{ marginLeft: '10px' }}>
            <Typography.Title level={4}>
              <Trans i18nKey="comingSoonBlock.text">get your invite once we launch</Trans>
            </Typography.Title>
          </Col>
        </Row>
      </div>
    );
  }
}

export const CTAComingSoon = CTAComingSoonComponent;
