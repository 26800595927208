import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { Trans } from 'react-i18next';
import { EAnchors } from 'common/const';
import { InfoItem } from 'common/components/InfoItem';
// import { EUserRoles } from 'common/models/userRequestObjects';
// import { getAppDomainName, goTo } from 'common/helpers/domainName.helper';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import { planeBackgroundPlx, planePlx } from 'common/PlxConfig';
import Plane from 'app/assets/images/Plane.svg';
import PlaneClouds from 'app/assets/images/PlaneClouds.svg';

export const P2PLending = ({ showModal }: { showModal: () => void }) => {
  // const goToSignUp = () => {
  //   const signUpUrl = getAppDomainName(`/sign-up?role=${EUserRoles.Investor}`);
  //   goTo(signUpUrl);
  // };

  return (
    <div className="p2p__section" id={EAnchors.MDFDifference}>
      <div className="violet-layout">
        <Row type="flex" className="mb-7" gutter={32}>
          <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
            <div className="p2p__title">
              <Trans i18nKey="p2pLending.mainTitle">
                <span className="ff-bold">Peer to Peer Lending</span>
                <br /> on MidFunder
              </Trans>
            </div>
            <div className="p2p__text">{i18n.t('p2pLending.text')}</div>
            <button className="p2p__btn" onClick={showModal}>
              {i18n.t('p2pLending.button')}
            </button>
          </Col>
          <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="p2p__image">
            <ParalaxContainer
              childrenParallaxData={[{ IParallaxData: planeBackgroundPlx }, { IParallaxData: planePlx }]}
              className="plane"
            >
              <img src={PlaneClouds} alt="Plane" width="100%" />
              <img src={Plane} alt="Plane" width="100%" />
            </ParalaxContainer>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" gutter={32}>
          {p2pItems.map((item, index) => (
            <Col xs={24} xl={12} key={index}>
              <InfoItem title={item.title} text={item.text} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

const p2pItems = [
  {
    title: 'p2pLending.statisticsTitle',
    text: 'p2pLending.statisticsText'
  },
  {
    title: 'p2pLending.underwritingTitle',
    text: 'p2pLending.underwritingText'
  }
];
