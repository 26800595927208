import React from 'react';
import { OurPartners } from 'entities/components/Partnership/OurPartners';
import { PartnerWithUs } from 'entities/components/Partnership/PartnerWithUs';

export const Partnership = () => {
  return (
    <>
      <OurPartners />
      <PartnerWithUs />
    </>
  );
};
