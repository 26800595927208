import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Col, Row } from 'antd';
// import { getAppDomainName, goTo } from 'common/helpers/domainName.helper';
// import { EUserRoles } from 'common/models/userRequestObjects';
import { RequirementsItem } from 'entities/components/Requirements/RequirementsItem';
import { ItemDescription } from 'entities/components/Requirements/ItemDescription';

export const Requirements = ({ showModal }: { showModal: () => void }) => {
  // const goToSignUp = () => {
  //   const signUpUrl = getAppDomainName(`/sign-up?role=${EUserRoles.Borrower}`);
  //   goTo(signUpUrl);
  // };

  return (
    <div className="requirements-section">
      <Row type="flex" justify="center" gutter={40}>
        <Col xs={24} md={8}>
          <RequirementsItem
            title={
              <Trans i18nKey="requirements.minimum">
                Minimum <br />
                <span className="ff-bold">requirements</span>
              </Trans>
            }
          >
            <ItemDescription
              title={`2 ${i18n.t('requirements.years')}`}
              tooltip={i18n.t('requirements.inBusiness')}
              titleColor="color-royal-blue"
              className="mb-9"
            />
            <ItemDescription title="A-F" tooltip={i18n.t('requirements.creditScore')} className="mb-9" />
            <ItemDescription title="100k CHF" tooltip={i18n.t('requirements.revenue')} />
          </RequirementsItem>
        </Col>
        <Col xs={24} md={8}>
          <RequirementsItem
            title={
              <Trans i18nKey="requirements.typical">
                Our <br />
                <span className="ff-bold">typical customer</span>
              </Trans>
            }
          >
            <ItemDescription
              title={`3+ ${i18n.t('requirements.years')}`}
              tooltip={i18n.t('requirements.inBusiness')}
              titleColor="color-royal-blue"
              className="mb-9"
            />
            <ItemDescription title="A" tooltip={i18n.t('requirements.creditScore')} className="mb-9" />
            <ItemDescription title="300k+ CHF" tooltip={i18n.t('requirements.revenue')} />
          </RequirementsItem>
        </Col>
        <Col xs={24} md={8}>
          <RequirementsItem
            title={
              <Trans i18nKey="requirements.seeMatching">
                Let’s see if <br />
                <span className="ff-bold">you are a match!</span>
              </Trans>
            }
            cardClassName="image-bg"
          >
            <button className="requirements-btn" onClick={showModal}>
              {i18n.t('requirements.getFunded')}
            </button>
          </RequirementsItem>
        </Col>
      </Row>
    </div>
  );
};
