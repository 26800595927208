import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { EAnchors } from 'common/const';
import Target from 'app/assets/icons/Target.svg';
import Puzzle from 'app/assets/icons/Puzzle.svg';
import Nameplate from 'app/assets/icons/Nameplate.svg';
import { ValueItem } from 'entities/components/OurValues/ValueItem';

const ourValues = [
  {
    icon: Target,
    title: 'valueTitle1',
    text: 'valueText1'
  },
  {
    icon: Puzzle,
    title: 'valueTitle2',
    text: 'valueText2'
  },
  {
    icon: Nameplate,
    title: 'valueTitle3',
    text: 'valueText3'
  }
];

export const OurValues = () => {
  return (
    <div className="our-values" id={EAnchors.Values}>
      <Row>
        <Col xs={24} md={12}>
          <div className="our-values__title">{i18n.t('ourValues.title')}</div>
          <div className="our-values__text">{i18n.t('ourValues.text')}</div>
        </Col>
      </Row>
      <Row type="flex" gutter={32}>
        {ourValues.map(item => (
          <ValueItem {...item} key={item.title} />
        ))}
      </Row>
    </div>
  );
};
