import React from 'react';

export enum CareersRoutes {
  CTO = 'cto'
}

export const careersTiles = [{ path: '/careers/cto', imageName: 'KYCVerif', title: 'careers.CTOTitle', text: 'careers.CTOText' }];

// interface IParagraph {
//   title: string;
//   text: string[] | Element[];
// }
export const ctoParagraphs = [
  {
    title: '',
    text: [
      'MidFunder is a FinTech company reinventing business lending. Our solutions enable small and medium sized businesses (SME’s) access to flexible funding fast and transparent',
      'As part of our Development Innovation Team based in Zurich, Switzerland, we are looking for a motivated and self-directed Full Stack Developer who is looking forward to working in a dynamic team and be part of a successful and lean organization.',
      'We are looking for a highly skilled computer programmer and an expert in machine learning who is comfortable with both front and back end programming and able to help extract value from our data. You will lead all the processes from data collection, cleaning, and pre-processing, to training models and deploying them to production.',
      'The ideal candidate will be passionate about artificial intelligence and stay up-to-date with the latest developments in the field and see out a project from conception to final product, requiring good organizational skills and attention to detail',
      'Responsible for developing and designing front end web architecture, ensuring the responsiveness of applications and working alongside graphic designers for web design features, among other duties.'
    ]
  },
  {
    title: 'Full Stack Developer Responsibilities:',
    text: [
      <ul key="1">
        <li>
          <p>Developing front end website architecture.</p>
        </li>
        <li>
          <p>Designing user interactions on web pages.</p>
        </li>
        <li>
          <p>Developing back end website applications.</p>
        </li>
        <li>
          <p>Creating servers and databases for functionality.</p>
        </li>
        <li>
          <p>Ensuring cross-platform optimization for mobile phones.</p>
        </li>
        <li>
          <p>Ensuring responsiveness of applications.</p>
        </li>
        <li>
          <p>Working alongside graphic designers for web design features.</p>
        </li>
        <li>
          <p>Seeing through a project from conception to finished product.</p>
        </li>
        <li>
          <p>Designing and developing APIs.</p>
        </li>
        <li>
          <p>Meeting both technical and consumer needs.</p>
        </li>
        <li>
          <p>Staying abreast of developments in web applications and programming languages.</p>
        </li>
      </ul>
    ]
  },
  {
    title: 'Machine Learning Responsibilities',
    text: [
      <ul key="1">
        <li>
          <p>
            Understanding business objectives and developing models that help to achieve them, along with metrics to track their
            progress
          </p>
        </li>
        <li>
          <p>Managing available resources such as hardware, data, and personnel so that deadlines are met</p>
        </li>
        <li>
          <p>
            Analysing the ML algorithms that could be used to solve a given problem and ranking them by their success probability
          </p>
        </li>
        <li>
          <p>
            Exploring and visualizing data to gain an understanding of it, then identifying differences in data distribution that
            could affect performance when deploying the model in the real world
          </p>
        </li>
        <li>
          <p>Verifying data quality, and/or ensuring it via data cleaning</p>
        </li>
        <li>
          <p>Supervising the data acquisition process if more data is needed</p>
        </li>
        <li>
          <p>Finding available datasets online that could be used for training</p>
        </li>
        <li>
          <p>Defining validation strategies</p>
        </li>
        <li>
          <p>Defining the pre-processing or feature engineering to be done on a given dataset</p>
        </li>
        <li>
          <p>Defining data augmentation pipelines</p>
        </li>
        <li>
          <p>Training models and tuning their hyperparameters</p>
        </li>
        <li>
          <p>Analysing the errors of the model and designing strategies to overcome them</p>
        </li>
        <li>
          <p>Deploying models to production</p>
        </li>
      </ul>
    ]
  },
  {
    title: 'Full Stack Machine Learning Developer Requirements:',
    text: [
      <ul key="1">
        <li>
          <p>Degree in Computer Science.</p>
        </li>
        <li>
          <p>Strong organizational and project management skills.</p>
        </li>
        <li>
          <p>Proficiency with fundamental front-end languages such as HTML, CSS and JavaScript.</p>
        </li>
        <li>
          <p>Familiarity with JavaScript frameworks such as Angular JS, React, Node.JS, Redux, Saga, Amber and Nest framework</p>
        </li>
        <li>
          <p>Proficiency with server-side languages such as Python, Ruby, Java, PHP and .Net.</p>
        </li>
        <li>
          <p>Familiarity with database technology such as Postgre SQL, MySQL, Oracle and MongoDB.</p>
        </li>
        <li>
          <p>Proficiency with Infrastructure components such as Docker, Kubernetes, GitLab CI, AWS, Google Cloud, Azure</p>
        </li>
        <li>
          <p>Familiarity with monitoring and alerting such as Sentry, Grafana Stack, ELK Stack</p>
        </li>
        <li>
          <p>Excellent verbal communication skills.</p>
        </li>
        <li>
          <p>Good problem-solving skills.</p>
        </li>
        <li>
          <p>Attention to detail.</p>
        </li>
        <li>
          <p>Proficiency with a deep learning framework such as TensorFlow or Keras</p>
        </li>
        <li>
          <p>Proficiency with Python and basic libraries for machine learning such as scikit-learn and pandas</p>
        </li>
        <li>
          <p>Expertise in visualizing and manipulating big datasets</p>
        </li>
        <li>
          <p>Proficiency with OpenCV</p>
        </li>
        <li>
          <p>Ability to select hardware to run an ML model with the required latency</p>
        </li>
      </ul>
    ]
  },
  {
    title: 'Thanks',
    text: [
      'Thank you for the time to consider us. We appreciate you taking the time to submit your application. Our Talent Acquisition team will carefully assess your application and will respond to you in a timely manner.',
      'Please apply by e-mail. For this position, please note that we do not consider applications from recruitment agencies.',
      'If you have any question you can contact us.',
      <a key="1" href="mailto:info@midfunder.com">
        info@midfunder.com
      </a>
    ]
  }
];

const mockDate = '17 May 2020';

export const tilesData = {
  cto: {
    date: mockDate,
    title: 'Full Stack Engineer',
    imageName: '',
    paragraphs: ctoParagraphs
  }
};
