import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Col, Row } from 'antd';
import { EAnchors } from 'common/const';
// import MCAProviders from 'app/assets/images/MCAProviders.png';
import MCA from 'app/assets/images/MCA.png';
import deMCA from 'app/assets/images/MCA_de.png';

export const MCASection = () => {
  let mcaImage = MCA;
  if (i18n.language.startsWith('de')) {
    mcaImage = deMCA;
  }
  return (
    <div className="loan-types-section" id={EAnchors.LoanTypes}>
      <div className="loan-types_title">{i18n.t('MCASection.title')}</div>
      <Row type="flex" gutter={12} style={{ alignItems: 'center' }}>
        <Col span={24}>
          <div className="loan-types_item_description_text" style={{ maxWidth: '100%' }}>
            <Trans i18nKey="MCASection.body">
              <p></p>
              <br />
              <p />
            </Trans>
          </div>
        </Col>
        {/* <Col span={12}>*/}
        {/*  <div>*/}
        {/*    <img src={MCAProviders} alt="MCA" width="100%" />*/}
        {/*  </div>*/}
        {/* </Col>*/}
      </Row>
      <Row type="flex" gutter={12} style={{ alignItems: 'center', paddingTop: '40px' }}>
        <Col lg={12} md={24}>
          <div className="loan-types_item_description_text pb-10" style={{ maxWidth: '100%' }}>
            {i18n.t('MCASection.example')}
          </div>
        </Col>
        <Col lg={12} md={24}>
          <img src={mcaImage} alt="MCA" width="100%" />
        </Col>
      </Row>
    </div>
  );
};
