import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { Trans } from 'react-i18next';
import { rocketPlx, rocketPlxBackground } from 'common/PlxConfig';
import { InfoItem } from 'common/components/InfoItem';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import Rocket from 'app/assets/images/Rocket.svg';
import RocketSmoke from 'app/assets/images/RocketSmoke.svg';

const profitsItems = [
  {
    title: 'growTitle',
    text: 'growText'
  },
  {
    title: 'fastTitle',
    text: 'fastText'
  },
  {
    title: 'stayTitle',
    text: 'stayText'
  },
  {
    title: 'flexTitle',
    text: 'flexText'
  },
  {
    title: 'transparentTitle',
    text: 'transparentText'
  }
];

export const Profits = () => {
  return (
    <div className="profits__section">
      <div className="violet-layout">
        <Row type="flex" gutter={32}>
          <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
            <div className="profits__title">
              <Trans i18nKey="profits.title">
                We are here to <br />
                <span className="ff-bold">unlock your potential</span>
              </Trans>
            </div>
            <div className="profits__text">{i18n.t('profits.text')}</div>
          </Col>
          <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="profits__image">
            <ParalaxContainer
              childrenParallaxData={[{ IParallaxData: rocketPlxBackground }, { IParallaxData: rocketPlx }]}
              className="rocket"
            >
              <img src={RocketSmoke} alt="Rocket" width="100%" />
              <img src={Rocket} alt="Rocket" width="100%" />
            </ParalaxContainer>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" gutter={32}>
          {profitsItems.map((item, index) => (
            <Col xs={24} xl={4} key={index}>
              <InfoItem title={`profits.${item.title}`} text={`profits.${item.text}`} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
