import React from 'react';
import { CommonPrivacyLayout, MainTitle } from 'app/assets/styles/base/commonStyledComponents';
import {
  DataPrivacyTitle,
  DataPrivacyText,
  DataPrivacyList,
  DataPrivacyListItem
} from 'entities/components/PrivacyPolicy/DataPrivacyLayout';

export const DataPrivacy: React.FC = () => {
  return (
    <CommonPrivacyLayout>
      <MainTitle>DATA PRIVACY STATEMENT</MainTitle>

      <DataPrivacyText>
        MidFunder AG (subsequently also called MidFunder, we or us) is highly interested in the security of personal data provided
        by you at the visit of our online platform. Your trust means a lot to us. This is why the following data privacy statement
        will provide information about how we ensure data security on the platform of MidFunder, which data is gathered and how
        the provided data is edited and used.
      </DataPrivacyText>
      <DataPrivacyText>
        Using our website and, as the case may be, with the creation of a personal account, you declare to accept the subsequently
        described data privacy statement as well as the depicted kind of elicitation, editing and usage of the data in it.
      </DataPrivacyText>
      <DataPrivacyText>
        Please note that we edit the data privacy statement from time to time without explicitly informing you and that only the
        most recent data privacy statement is valid. Periodical reading of the recent version is therefore recommended. You can
        access the data privacy statement in the footer.
      </DataPrivacyText>

      <DataPrivacyTitle>WHICH DATA ABOUT YOU WILL BE GATHERED, EDITED AND USED?</DataPrivacyTitle>
      <DataPrivacyText>
        The kind and extent of an elicitation and usage of your data differ on how you use the website of MidFunder. Thereby we
        differ whether you use the website purely for informative reasons or whether you utilize the services (e.g. application
        for the newsletter) and/or whether you utilize the secured section. The latter is only accessible after prior registration
        and with a personal login.
      </DataPrivacyText>

      <DataPrivacyTitle>USAGE OF THE WEBSITE WITHOUT REGISTRATION</DataPrivacyTitle>
      <DataPrivacyText>
        A major part of our website can be used without registration. As a non-registered user, no personal data is going to be
        gathered, except of the data automatically recorded by our web server by visiting the MidFunder-website. Such computer
        related data are, among others the anonymised IP-address, the type of used browser-software including settings, date and
        time of your access as well as the duration of the visit.
      </DataPrivacyText>
      <DataPrivacyText>
        While visiting websites in general your browser obligatorily gathers this data and transmits it. As a rule, this data does
        not contain any personal information and therefore cannot be attributed to a specific person.
      </DataPrivacyText>

      <DataPrivacyTitle>ELICITATION OF PERSONAL DATA (FOR REGISTRATION AND/OR USAGE OF SERVICES)</DataPrivacyTitle>
      <DataPrivacyText>
        Personal data is related to a certain or determinable person and let us draw conclusions about regarding your identity. At
        the visit and usage of our website we gather, save and edit among others the following personal data:
      </DataPrivacyText>
      <DataPrivacyText>
        User data provided by yourself by filling out the forms on our website, such as any information at the entry to the
        registration site or the subscription to the newsletter (e.g. personal details like name, date of birth, marital status,
        address, phone number, e-mail-address, etc.)
      </DataPrivacyText>
      <DataPrivacyText>
        Further information, that are required for the usage and performance of services via our website
      </DataPrivacyText>
      <DataPrivacyText>
        MidFunder only gathers data which are required for the performance of requested services via our website. No further
        personal data is gathered, as long as you don’t supply them with your agreement.
      </DataPrivacyText>

      <DataPrivacyTitle>FOR WHAT REASON IS DATA GATHERED?</DataPrivacyTitle>
      <DataPrivacyText>
        MidFunder gathers, saves and uses data – personal as well as non-personal – for the following purposes:
      </DataPrivacyText>
      <DataPrivacyList>
        <DataPrivacyListItem>for an optimal, efficient supply and execution of our services;</DataPrivacyListItem>
        <DataPrivacyListItem>for an efficient administration and continuous improvement of our website;</DataPrivacyListItem>
        <DataPrivacyListItem>for the analysis of user related usage-behaviour on our website;</DataPrivacyListItem>
        <DataPrivacyListItem>for a website, optimally aligned to your needs;</DataPrivacyListItem>
        <DataPrivacyListItem>
          for the distribution of newsletters as well as for (personalized) marketing measures, given that the user has granted us
          a respective permission;
        </DataPrivacyListItem>
        <DataPrivacyListItem>as well as for further purposes concerning the operation of our website</DataPrivacyListItem>
      </DataPrivacyList>

      <DataPrivacyTitle>TRANSFER OF INFORMATION TO THIRD PARTIES</DataPrivacyTitle>
      <DataPrivacyText>
        MidFunder can transfer gathered data for the above-mentioned purposes to connected affiliates, cooperation partners and to
        third parties inland or abroad. The respective data-recipients that have access to the data gathered by MidFunder are
        obligated to respect data security. Third party contractors obtain data only to an essential extent and are obligated to
        use the data only for order fulfilment and not for other purposes. For cross-border data transfer we secure through
        agreements with the data-recipients that they obligate themselves to an appropriate data-security.
      </DataPrivacyText>
      <DataPrivacyText>
        Furthermore, disclosure of your data towards controlling authorities, legal authorities and other responsible people is
        only explicitly legitimate if the applicable law says so.
      </DataPrivacyText>
      <DataPrivacyText>
        Bilaterally contractual data-security terms with credit-takers and investors of Midfunder remain reserved.
      </DataPrivacyText>

      <DataPrivacyTitle>USAGE OF COOKIES</DataPrivacyTitle>
      <DataPrivacyText style={{ fontSize: '20px', fontWeight: 'bold' }}>
        What are cookies and how are they used on our platform?
      </DataPrivacyText>
      <DataPrivacyText>
        Our website use technologies such as cookies to gather certain information and to design the website according to your
        needs. Cookies have the purpose to ease communication and web-usage.
      </DataPrivacyText>
      <DataPrivacyText>
        Cookies are small text-files which are placed on your local device (PC, smartphone, tablet,…) via your browser at your
        visit on the Midfunder-website and which temporarily or permanently save specific, device-related information. These might
        be, for example the registration of your browser type, visited pages or language settings. Thereby our web server is able
        to recognize the devices of users at the next visit, as soon as a connection to your browser is made. This is how the
        cookie helps provide the deployment and presentation of an individualized and matched to user-needs website so that you
        can orient yourself better on our website at a following visit and use it more efficiently. It is important to mention
        that an identification of only the device, not the person, takes place.
      </DataPrivacyText>
      <DataPrivacyText>
        Among others, we use the two following kinds of cookies on our website: Session cookies: These are temporary cookies that
        stay in the cookie-data of your browser until you leave our website and that are automatically deleted right after you
        close the browser. These cookies are necessary for certain applications or functions on the website, so that these can be
        operated properly.
      </DataPrivacyText>
      <DataPrivacyText>
        Permanent cookies: We can use permanent cookies for a better user-friendliness (e.g. for the supply of an optimal
        navigation). These cookies remain in the cookie-file of your browser for a longer time. The duration depends on the
        settings of your browser. Permanent cookies allow the transmission of information to our web server at every visit of our
        website. They are also known as tracking cookies.
      </DataPrivacyText>
      <DataPrivacyText>
        Midfunder uses cookie technology also for statistical, analytical and marketing-technical purposes. Thereby we can
        recognize the habits of our visitors and registered users, as well as the kind and number of visits on our website, and to
        record trends and to compute statistics. This helps to recognize the potential of improvement of our website and to grant
        a continuous optimization concerning user-friendliness, speed and performance.
      </DataPrivacyText>
      <DataPrivacyText>
        We point out that you can generally control by yourself and respectively reject the storing of cookies on your device via
        the web browser. Furthermore, you’re able to delete already set cookies again. You can inform yourself hereof in the menu
        bar of your browser through the help-function and you can select the preferred settings. Please note though, that a
        deactivation of cookies can result in limited access to our website.
      </DataPrivacyText>

      <DataPrivacyTitle>WEB ANALYSIS / TRACKING</DataPrivacyTitle>
      <DataPrivacyText>
        On our website, we use diverse web analysis tools (such as Google Analytics, Google AdWords, Google Tag Manager, Mouseflow
        and Hotjar) to record user behaviour of visitors through tracking data and to evaluate it. Therefore, we cooperate with
        third-party contractors, which help us to gather this data. Technically, these tools work with cookies as well. The data
        which are generated through the tracking code and the cookie at your visit on our website can be transmitted to
        third-party suppliers, which provide the service, for analytic purposes. Cookies with whom tracking data are gathered
        don’t receive personal data which means that the IP-address is shortened immediately on your device and therefore
        anonymized. Thus, an anonymized transmission and saving of the data is secured. These data are gathered for the purpose of
        continuous optimization of our web performance such as on the improvement of our advertising measures. From the respective
        website of a third-party supplier you can obtain detailed disclosure about the data that is gathered by the respective
        supplier. You can avoid the gathering of your data with the help of add-ons.
      </DataPrivacyText>

      <DataPrivacyTitle>INTERACTION WITH SOCIAL NETWORKS</DataPrivacyTitle>
      <DataPrivacyText>
        We use social plug-ins (subsequent: plug-ins) of social networks on the web. These plug-ins are in each case recognizable
        through the logo on our website respective to the network. If you call up a website with such a plugin, a direct
        connection with the servers of the respective social network is established. It is important to note that we use a so
        called two-click-solution of the plugin for your security. This means that plug-ins on our website are inactive at first.
        Only with a click on one of the logos the plugin is activated and a connection such as data transmission – e.g. that you
        visit our website – is granted. If you are logged in to this network with a username and password at the same time, all
        your interactions can be associated with the plug-in respective to your profile. For example, contents of our website are
        linked with your profile. Other users of the network can learn about such interactions. Midfunder is neither somehow
        connected to third-party contractors whose websites are integrated through plugins nor somehow responsible for them. Even
        if you’re not a member of the social network or not logged in to, it is still possible that the IP-address is transmitted
        and saved.
      </DataPrivacyText>
      <DataPrivacyText>
        Subsequently, you find a list of social networks whose plug-ins are integrated on our website. You can find contact data
        and data security guidelines with details to the respective contractors on their respective websites.
      </DataPrivacyText>
      <DataPrivacyList>
        <DataPrivacyListItem>Linkedin</DataPrivacyListItem>
        <DataPrivacyListItem>Facebook</DataPrivacyListItem>
        <DataPrivacyListItem>Twitter</DataPrivacyListItem>
        <DataPrivacyListItem>Google+</DataPrivacyListItem>
        <DataPrivacyListItem>Youtube</DataPrivacyListItem>
        <DataPrivacyListItem>Vimeo</DataPrivacyListItem>
      </DataPrivacyList>

      <DataPrivacyTitle>STORAGE, DATA SECURITY AND SECURITY OF TRANSMISSION</DataPrivacyTitle>
      <DataPrivacyText>
        The data gathered by Midfunder are under the highest confidentiality standards and are treated with outmost care and
        discretion. For the protection from non-authorized access, destruction, misuse, disclosure and/or manipulation Midfunder
        has implemented adequate technical and organisational measures. All data are stored on servers within Switzerland, whose
        data centers fulfill the highest requirements. We further point out that we also take internal company data security very
        seriously. All our co-workers and service companies delegated by us are obligated to data security and secrecy have been
        instructed about the legal guidelines.
      </DataPrivacyText>
      <DataPrivacyText>
        Communication through our website takes place with a secure SSL-codification (secure-socket-layer). Please note that it
        can still occur that data which you transmit to Midfunder through the internet or an e-mail-service can be illegally
        recognised by third parties. It is further possible that the data, due to the nature of the internet and under certain
        circumstances circulates through servers of third-party countries, which means also countries that do not offer the same
        data security level as your domicile country. You should therefore not transmit very confidential information through the
        internet (e.g. e-mail).
      </DataPrivacyText>
      <DataPrivacyText>
        On the other hand, Midfunder can assure you that the data will be treated according to the applicable data security
        guidelines and that everything will be done for the protection against impermissible access through third-parties.
        Nonetheless, we can’t guarantee for confidentiality and comprehensive protection of the messages and therefore can’t take
        the accountability.
      </DataPrivacyText>
      <DataPrivacyText>We store your personal data only for as long as the purpose or legal regulations require.</DataPrivacyText>

      <DataPrivacyTitle>DISCLOSURE / DELETION OF DATA</DataPrivacyTitle>
      <DataPrivacyText>
        On written request, users of our website have the right and the possibility to receive disclosure or a listing of saved
        data related to themselves at every time. Furthermore, they possess the right-on adjustment, blockage and deletion of this
        data, as long as no legal or regulating guidelines prevent that. Disclosures such as request about the deletion and
        blockage of data can only be elaborated by us if your identification is sufficient.
      </DataPrivacyText>

      <DataPrivacyTitle>APPLICABILITY</DataPrivacyTitle>
      <DataPrivacyText>
        This data privacy statement scopes for all data which we receive due to your usage of our website. It doesn’t involve the
        contents of third-party websites, even if you get to those third-party websites through links on our website. We don’t
        have any influence on the content, data editing and data management of third-party websites and can therefore not take
        responsibility for them. We ask you to consider the specific data privacy statements of the respective third-party
        websites.
      </DataPrivacyText>
      <DataPrivacyText>
        With the handling of your personal data Midfunder is under Swiss data privacy law. Further data privacy laws are
        applicable if your data is edited (e.g. through a charged service contractor) abroad.
      </DataPrivacyText>
      <DataPrivacyText>Version 1.0, effective September 2019.</DataPrivacyText>
    </CommonPrivacyLayout>
  );
};
