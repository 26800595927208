import { EAnchors, ERoutes } from 'common/const';

enum ELanguage {
  En = 'en',
  De = 'de',
  It = 'it',
  Fr = 'fr'
}

export interface IMenuItem {
  title: string;
  iconName?: string;
  anchor?: string;
  menuPoints: INavItemData[];
}

export interface INavItemData {
  title: string;
  value: string;
}

export const menuItems: IMenuItem[] = [
  // {
  //   title: 'loanCalculator',
  //   anchor: ERoutes.LoanCalculator,
  //   menuPoints: []
  // },
  {
    title: 'borrow',
    iconName: 'ArrowDown',
    menuPoints: [
      { title: 'howItWorks', value: EAnchors.HowItWorks },
      { title: 'loanTypes', value: EAnchors.LoanTypes },
      { title: 'creditRatings', value: EAnchors.CreditRatings },
      { title: 'faq', value: ERoutes.FAQBorrower }
    ]
  },
  {
    title: 'invest',
    iconName: 'ArrowDown',
    menuPoints: [
      // { title: 'mdfDifference', value: EAnchors.MDFDifference },
      { title: 'whyMdf', value: EAnchors.WhyMDF },
      { title: 'faq', value: ERoutes.FAQInvestor }
    ]
  },
  {
    title: 'partner',
    anchor: EAnchors.Partner,
    menuPoints: []
  },
  {
    title: 'about',
    iconName: 'ArrowDown',
    menuPoints: [
      { title: 'aboutUs', value: EAnchors.AboutUs },
      { title: 'team', value: EAnchors.Team },
      { title: 'career', value: EAnchors.Career },
      { title: 'values', value: EAnchors.Values },
      { title: 'academy', value: ERoutes.Academy }
    ]
  }
];

export const languageMenu: IMenuItem = {
  title: '',
  iconName: 'ArrowDown',
  menuPoints: [
    { title: 'En', value: ELanguage.En },
    { title: 'De', value: ELanguage.De }
    // { title: 'It', value: ELanguage.It },
    // { title: 'Fr', value: ELanguage.Fr }
  ]
};
