import React, { Component } from 'react';
import window from 'global/window';
import { Col, Row } from 'antd';
import { ImageSVG } from 'common/components/ImageSVG';
import { CareersRoutes, tilesData } from 'entities/components/Careers/Careers.const';

class CareerItemComponent extends Component {
  render() {
    const tileAddress = window.location && window.location.pathname.split('careers/')[1];
    const tileData = this.getTileData(tileAddress);

    return (
      <Row type="flex" justify="center" className="common-layout">
        <Col lg={20} xl={18} xxl={16}>
          <Row type="flex" justify="center">
            <Col span={24} className="secondary-title text-center">
              {tileData.title}
            </Col>
          </Row>
          <div className="tile-page-image">{tileData.imageName && <ImageSVG name={tileData.imageName} />}</div>

          {tileData.paragraphs.map((item, index) => (
            <>
              {item.title && <div className="mt-12 pt-7 mb-7 sub-title tile-title">{item.title}</div>}
              {item.text &&
                (item.text as (string | Element)[]).map((item, index) => {
                  return (
                    <div className="mb-6 tile-text" key={index}>
                      {item}
                    </div>
                  );
                })}
            </>
          ))}
        </Col>
      </Row>
    );
  }

  getTileData = (path: string) => {
    if (path === CareersRoutes.CTO) {
      return tilesData.cto;
    } else {
      return tilesData.cto;
    }
  };
}

export const CareerItem = CareerItemComponent;
