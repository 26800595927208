export const mdfEmail = 'info@midfunder.com';
export const mdfTel = '+41 44 508 33 55';

export enum ERoutes {
  Main = '*',
  LegalNotice = 'legal-notice',
  UserTerms = 'user-terms',
  DataPrivacy = 'data-privacy',
  LoanCalculator = 'loan-calculator',
  FAQInvestor = 'faq-investor',
  FAQBorrower = 'faq-borrower',
  Academy = 'academy',
  AcademyItem = 'academy/*',
  Careers = 'careers',
  CareerItem = 'careers/*'
}

export enum EAnchors {
  HowItWorks = '#how-it-works',
  LoanTypes = '#loan-types',
  CreditRatings = '#credit-ratings',
  MDFDifference = '#midfunder-difference',
  WhyMDF = '#why-midfunder',
  Partner = '#partner',
  AboutUs = '#about-us',
  Team = '#team',
  Career = '#career',
  Values = '#values'
}
