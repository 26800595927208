import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from 'common/locales/en/translation.json';
import enErrors from 'common/locales/en/errors.json';
import deTranslation from 'common/locales/de/translation.json';
import deErrors from 'common/locales/de/errors.json';
import itTranslation from 'common/locales/it/translation.json';
import itErrors from 'common/locales/it/errors.json';
import frTranslation from 'common/locales/fr/translation.json';
import frErrors from 'common/locales/fr/errors.json';

const resources = {
  en: {
    translation: enTranslation,
    errors: enErrors,
  },
  de: {
    translation: deTranslation,
    errors: deErrors,
  },
  it: {
    translation: itTranslation,
    errors: itErrors,
  },
  fr: {
    translation: frTranslation,
    errors: frErrors,
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'navigator'],
    },
    resources,
    ns: ['translation', 'errors'],
    defaultNS: 'translation',
    load: 'languageOnly',
    whitelist: ['de', 'en'],
    fallbackLng: ['en'],
  });

export default i18n;
