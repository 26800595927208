import React from 'react';
import Plx from 'react-plx';

export interface IParallaxData {
  start: string | number;
  end?: string | number;
  startOffset?: string | number;
  endOffset?: string | number;
  duration: string;
  easing?:
    | 'ease'
    | 'easeIn'
    | 'easeOut'
    | 'easeInOut'
    | 'easeInSine'
    | 'easeOutSine'
    | 'easeInOutSine'
    | 'easeInQuad'
    | 'easeOutQuad'
    | 'easeInOutQuad'
    | 'easeInCubic'
    | 'easeOutCubic'
    | 'easeInOutCubic'
    | 'easeInQuart'
    | 'easeOutQuart'
    | 'easeInOutQuart'
    | 'easeInQuint'
    | 'easeOutQuint'
    | 'easeInOutQuint'
    | 'easeInExpo'
    | 'easeOutExpo'
    | 'easeInOutExpo'
    | 'easeInCirc'
    | 'easeOutCirc'
    | 'easeInOutCirc';
  properties: {
    startValue?: string | number;
    endValue?: string | number;
    property:
      | 'translateX'
      | 'translateY'
      | 'translateZ'
      | 'skew'
      | 'skewX'
      | 'skewY'
      | 'skewZ'
      | 'rotate'
      | 'rotateX'
      | 'rotateY'
      | 'rotateZ'
      | 'scale'
      | 'scaleX'
      | 'scaleY'
      | 'scaleZ'
      | 'opacity';
    unit?: string;
  }[];
}

interface IComponentProps {
  childrenParallaxData: { IParallaxData?: IParallaxData[]; childClassName?: string }[];
  className?: string;
}

export const ParalaxContainer: React.FC<IComponentProps> = props => {
  const { children, childrenParallaxData, className = '' } = props;
  return (
    <div className={`paralax-container paralax-container__${className}`}>
      {React.Children.map(children, (child, index) => (
        <Plx
          parallaxData={childrenParallaxData[index] ? childrenParallaxData[index].IParallaxData : undefined}
          className={childrenParallaxData[index] ? childrenParallaxData[index].childClassName : ''}
        >
          <div className="paralax-container__image-wrapper">{child}</div>
        </Plx>
      ))}
    </div>
  );
};
