export interface IFaqTile {
  title: string;
  text: string;
  secondParagraph?: string;
}

export const investorsFaq: IFaqTile[] = [
  {
    title: 'investorsFaq1tile.title',
    text: 'investorsFaq1tile.text'
  },
  {
    title: 'investorsFaq2tile.title',
    text: 'investorsFaq2tile.text'
  },
  {
    title: 'investorsFaq3tile.title',
    text: 'investorsFaq4tile.text'
  },
  {
    title: 'investorsFaq4tile.title',
    text: 'investorsFaq4tile.text'
  },
  {
    title: 'investorsFaq5tile.title',
    text: 'investorsFaq5tile.text'
  },
  {
    title: 'investorsFaq6tile.title',
    text: 'investorsFaq6tile.text'
  },
  {
    title: 'investorsFaq7tile.title',
    text: 'investorsFaq7tile.text'
  },
  {
    title: 'investorsFaq8tile.title',
    text: 'investorsFaq8tile.text'
  }
];

export const borrowersFaq: IFaqTile[] = [
  {
    title: 'borrowersFaq1tile.title',
    text: 'borrowersFaq1tile.text'
  },
  {
    title: 'borrowersFaq2tile.title',
    text: 'borrowersFaq2tile.text',
    secondParagraph: 'borrowersFaq2tile.secondParagraph'
  },
  {
    title: 'borrowersFaq3tile.title',
    text: 'borrowersFaq4tile.text'
  },
  {
    title: 'borrowersFaq4tile.title',
    text: 'borrowersFaq4tile.text'
  },
  {
    title: 'borrowersFaq5tile.title',
    text: 'borrowersFaq5tile.text'
  },
  {
    title: 'borrowersFaq6tile.title',
    text: 'borrowersFaq6tile.text'
  },
  {
    title: 'borrowersFaq7tile.title',
    text: 'borrowersFaq7tile.text'
  },
  {
    title: 'borrowersFaq8tile.title',
    text: 'borrowersFaq8tile.text'
  },
  {
    title: 'borrowersFaq9tile.title',
    text: 'borrowersFaq9tile.text'
  }
];
