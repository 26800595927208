export const currency: string = 'CHF';

export const intFormat = '0, 0 ' + currency;

export const marks: { [key: number]: string } = {
  0: 'A',
  20: 'B',
  40: 'C',
  60: 'D',
  80: 'E',
  100: 'F'
};

export const percentsByRating: { [key: string]: number } = {
  A: 3.29,
  B: 4.89,
  C: 5.47,
  D: 9.21,
  E: 12.59,
  F: 0
};

export interface IScheduleItem {
  period: string;
  repayment: string;
  payment: string;
  total: string;
  key: string;
}

export enum ECreditInfo {
  Total = 'total',
  Interest = 'interest',
  MonthlyPayment = 'monthlyPayment'
}

export enum ECalcFieldNames {
  Sum = 'sum',
  Period = 'period',
  Rating = 'rating'
}
