import React from 'react';
import { Col, Row } from 'antd';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { EAnchors } from 'common/const';
import { mainImagePlx, mainImagePlxBackground } from 'common/PlxConfig';
// import { CTAInput } from 'common/components/CTAInput';
import { CTAComingSoon } from 'common/components/CTAComingSoon';
// import { EUserRoles } from 'common/models/userRequestObjects';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import ManWithJetpack from 'app/assets/images/ManWithJetpack.svg';
import ManWithJetpackPlanets from 'app/assets/images/ManWithJetpackPlanets.svg';
// import { creditRatingsItems } from 'entities/components/MainSection/MainSection.const';
// import { CreditRatingItem } from 'entities/components/MainSection/CreditRatingItem';

export const MainSection = () => {
  return (
    <div className="main-section" id={EAnchors.CreditRatings}>
      <Row type="flex" gutter={32}>
        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }}>
          <h1 className="main-title">
            <Trans i18nKey="mainSection.title">
              We are on the path to <br />
              <span className="ff-bold">reinvent business lending</span>
            </Trans>
          </h1>
          <p className="main-text">{i18n.t('mainSection.text')}</p>
          {/* <CTAInput pathTo={`/sign-up?role=${EUserRoles.Borrower}`} />*/}
        </Col>
        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="text-center">
          <ParalaxContainer
            childrenParallaxData={[{ IParallaxData: mainImagePlxBackground }, { IParallaxData: mainImagePlx }]}
            className="mainImage"
          >
            <img src={ManWithJetpackPlanets} alt="Man with jetpack" width="100%" className="main-image" />
            <img src={ManWithJetpack} alt="Man with jetpack" width="100%" className="main-image" />
          </ParalaxContainer>
        </Col>
      </Row>
      <CTAComingSoon skipHeader={false} />
      {/* <Row type="flex" className="credit-rating-info">*/}
      {/*  {creditRatingsItems.map((item, index) => (*/}
      {/*    <CreditRatingItem title={item.itemKey} value={item.itemValue} key={index} />*/}
      {/*  ))}*/}
      {/* </Row>*/}
    </div>
  );
};
