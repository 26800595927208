import styled from 'styled-components';
import { SmallText, SubTitle } from 'app/assets/styles/base/commonStyledComponents';
import { color, font, fontSize, mediaBreakpoints } from 'app/assets/styles/base/styledVariables';

export const UserTermsTitle = styled(SubTitle)`
  padding: 0 7.5%;
  align-self: center;
`;

export const UserTermsText = styled(SmallText)`
  text-align: left;
  color: ${color.grayDark};
  margin-bottom: 18px;
`;

export const BoldText = styled.span`
  text-align: left;
  color: ${color.grayDark};
  margin-bottom: 18px;
  font-family: ${font.bold};

  @media (max-width: 1439px) {
    font-size: 15px;
  }

  @media (max-width: ${mediaBreakpoints.md}) {
    font-size: ${fontSize.xxs};
  }
`;

export const UserTermsLink = styled.a`
  font-size: ${fontSize.xs};
  line-height: 132%;
  text-align: center;
  color: ${color.blue};
  margin-bottom: 18px;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1439px) {
    font-size: 15px;
  }

  @media (max-width: ${mediaBreakpoints.md}) {
    font-size: ${fontSize.xxs};
  }
`;
