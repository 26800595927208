export interface ISimpleUser {
  email: string;
}

export interface IPartnerUser {
  email: string;
  name: string;
  title?: string;
  organization?: string;
  comments: string;
}

export enum EUserRoles {
  Investor = 'investor',
  Borrower = 'borrower'
}
