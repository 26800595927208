import React, { Component } from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { ImageSVG } from 'common/components/ImageSVG';

interface IComponentProps {
  date?: string;
  title?: JSX.Element;
  imageName?: string;
}

class AcademyTilePageLayoutComponent extends Component<IComponentProps> {
  render() {
    const { children, date, title, imageName } = this.props;
    return (
      <Row type="flex" justify="center" className="common-layout">
        <Col lg={20} xl={18} xxl={16}>
          <Row type="flex" justify="center">
            <Col span={24} className="secondary-title text-center">
              {title}
            </Col>
          </Row>
          <div className="tile-page-image">{imageName && <ImageSVG name={imageName} />}</div>
          {children}
          {date && (
            <div className="mt-12 pt-7 tile-page-date">
              {i18n.t('Published')}: {date}
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export const AcademyTilePageLayout = AcademyTilePageLayoutComponent;
