import React from 'react';
import i18n from 'i18next';
import { Col, Row } from 'antd';
import { EAnchors } from 'common/const';
import { ParalaxContainer } from 'common/components/ParalaxContainer';
import { manOnStairsPlanetsPlx } from 'common/PlxConfig';
import { goTo } from 'common/helpers/domainName.helper';
import ManOnStairs from 'app/assets/images/ManOnStairs.svg';
import ManOnStairsPlanets from 'app/assets/images/ManOnStairsPlanets.svg';

export const CareerAtMidfunder = () => {
  return (
    <div className="careers" id={EAnchors.Career}>
      <Row type="flex" align="middle">
        <Col xs={{ span: 24, order: 2 }} xl={{ span: 14, order: 1 }}>
          <div className="careers__title">{i18n.t('careers.title')}</div>
          <div className="careers__text">{i18n.t('careers.text')}</div>
          <button className="careers__btn" onClick={() => goTo('/careers')}>
            {i18n.t('careers.button')}
          </button>
        </Col>
        <Col xs={{ span: 24, order: 1 }} xl={{ span: 10, order: 2 }} className="careers__image">
          <ParalaxContainer childrenParallaxData={[{ IParallaxData: manOnStairsPlanetsPlx }]} className="manOnStairs">
            <img src={ManOnStairsPlanets} alt={ManOnStairs} />
            <img src={ManOnStairs} alt={ManOnStairs} />
          </ParalaxContainer>
        </Col>
      </Row>
    </div>
  );
};
