import React from 'react';

interface IComponentProps {
  title: string;
  titleColor?: string;
  tooltip: string;
  className?: string;
}

export const ItemDescription = (props: IComponentProps) => {
  const { title, tooltip, titleColor = 'color-gun-powder', className } = props;
  return (
    <div className={`description ${className}`}>
      <div className={`description_title ${titleColor}`}>{title}</div>
      <div className="description_tooltip">{tooltip}</div>
    </div>
  );
};
