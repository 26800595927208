import styled from 'styled-components';
import { color, font, fontSize, mediaBreakpoints } from 'app/assets/styles/base/styledVariables';

export const FlexColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const MainTitle = styled.div`
  font-family: ${font.bold};
  font-size: ${fontSize.xxlg};
  line-height: 132%;
  text-align: center;
  color: ${color.black};
  margin-bottom: 20px;

  @media (max-width: ${mediaBreakpoints.md}) {
    font-size: ${fontSize.xlg};
  }

  @media (max-width: ${mediaBreakpoints.sm}) {
    font-size: ${fontSize.lg};
  }
`;

export const SubTitle = styled.div`
  font-family: ${font.medium};
  font-size: ${fontSize.lg};
  color: ${color.blackLight};

  @media (max-width: ${mediaBreakpoints.md}) {
    font-size: ${fontSize.sm};
  }
`;

export const SmallText = styled.span`
  font-size: ${fontSize.xs};
  text-align: center;
  color: ${color.gray};

  @media (max-width: ${mediaBreakpoints.md}) {
    font-size: ${fontSize.xxs};
  }
`;

export const CTAInput = styled.input`
  border: 1px solid ${color.grayLight};
  border-radius: 6px;
  width: 350px;
  height: 40px;
  outline: none;
  padding: 8px 12px;
  margin: 0 24px 12px 0;
  font-size: ${fontSize.xs};

  &::placeholder {
    font-size: ${fontSize.xs};
    color: #dedede;
  }

  :focus {
    border: 1px solid ${color.blue};
  }

  :hover {
    border: 1px solid ${color.blue};
  }

  @media (max-width: 1365px) {
    height: 36px;
  }

  @media (max-width: ${mediaBreakpoints.sm}) {
    margin: 0 0 12px 0;

    &::placeholder {
      font-size: ${fontSize.xxs};
    }
  }

  @media (max-width: 374px) {
    &::placeholder {
      font-size: ${fontSize.xxxs};
    }
  }
`;

export const CommonPrivacyLayout = styled(FlexColumn)`
  width: 100%;
  padding: 133px 20% 20px;
  align-items: flex-start;

  @media (max-width: 479px) {
    padding: 112px 10% 20px;
  }
`;
