import React from 'react';
import ReactSVG from 'react-svg';
import styled from 'styled-components';

const SReactSVG = styled(ReactSVG)`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

interface IComponentProps {
  name: string;
}

export const ImageSVG: React.FC<IComponentProps> = props => {
  return <SReactSVG src={require(`../../app/assets/images/${props.name}.svg`)} style={{ width: '100%' }} />;
};
